import React from "react"
import Page from "@components/Layout/DocumentationPage"
import Paragraph from "../../components/Paragraph"
import Code from "../../components/Code"

export default () => (
  <Page title={"IP Ranges"}>
    <Paragraph>
      We serve our application from a single IP for both in and outbound
      requests. The IP address is:
    </Paragraph>
    <Code language={"php"}>198.211.118.42</Code>
    <Paragraph>
      Certain integrations can be used with self-hosted services (eg: Bitbucket
      Server). You will need to whitelist our IP if you're hosting this behind a
      firewall.
    </Paragraph>
  </Page>
)

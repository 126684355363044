import PropTypes from "prop-types"
import React, { useEffect } from "react"
import Prism from "prismjs"
import Box from "./Box"

const Code = ({ children, language }) => {
  useEffect(() => {
    Prism.highlightAll()
  }, [language])

  return (
    <Box as={"pre"} my={2}>
      <code className={`language-${language}`}>{children}</code>
    </Box>
  )
}

Code.propTypes = {
  language: PropTypes.string.isRequired,
}

export default Code
